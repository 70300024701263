// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbY5WqLwZFsaVSSqIt4WIka4jsiEWwMLo",
  authDomain: "djjs-1ea9f.firebaseapp.com",
  projectId: "djjs-1ea9f",
  storageBucket: "djjs-1ea9f.appspot.com",
  messagingSenderId: "686732848437",
  appId: "1:686732848437:web:3ac0599eff7044b04c307c",
  measurementId: "G-T9V8Y5B3S9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);