
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';

import { useAuth } from '../firebase/auth';



function EventForm() {
  const navigate = useNavigate();
  const { authUser, isLoading } = useAuth();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Event Form
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="eventName"
            name="eventName"
            label="Event name"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Pin code"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled
            variant="contained"
            type="submit"
            sx={{ mt: 3 }}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default EventForm;
