
import { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, CircularProgress } from '@mui/material';

import { auth } from '../firebase/firebase';
import { useAuth } from '../firebase/auth';

const REDIRECT_PAGE = '/dashboard';

function Home() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { authUser, isLoading } = useAuth();

  const onSubmit = async (e) => {
    e.preventDefault()

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate(REDIRECT_PAGE);
      })
      .catch((error) => {
        // ..
      });
  }

  useEffect(() => {
    if (!isLoading && authUser) {
      navigate(REDIRECT_PAGE);
    }
  }, [authUser, isLoading])

  if (isLoading || (!isLoading && !!authUser)) {
    return <CircularProgress color="inherit" sx={{ marginLeft: '50%', marginTop: '25%' }}/>;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={onSubmit} noValidate sx={{ marginTop: 10, display: "flex", flexDirection: "column" }}>
        <TextField
          margin="normal"
          required
          id="standard-required"
          variant="standard"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          id="standard-password-input"
          variant="standard"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          variant="contained"
          type="submit"
        >
          Log in
        </Button>
      </Box>
    </Container>
  );
}

export default Home;
