
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Paper, Box, Tabs, Tab, Typography, TextField, Stack, Button} from '@mui/material';

import { useAuth } from '../firebase/auth';
import EventForm from '../components/event-form';
import EventRow from '../components/event-row';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 1, py: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Dashboard() {
  const [tab, setTab] = React.useState(0);
  const navigate = useNavigate();
  const { authUser, isLoading } = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', marginTop: 8 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Request Event"  />
          <Tab label="Events" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <EventForm />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Stack spacing={2}>
          <EventRow>
            07/01/23 Ram Katha
            <Box sx={{ml:8}}>
              <Button >Add to calender</Button>
              <Button >Email</Button>
              <Button >Watsapp</Button>
            </Box>
          </EventRow>
        </Stack>
      </TabPanel>
    </Box>
  );
}

export default Dashboard;
