import { BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';

import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import { AuthUserProvider } from './firebase/auth';


function App() {
  return (
    <AuthUserProvider>
      <div className="App">
        <header className="App-header">
          <title>DJJS</title>
        </header>
        
        <main>
            <AppBar>
              <Toolbar>
                <Typography variant="h6" component="div"> 
                  DJJS Nurmahal
                </Typography> 
              </Toolbar>
            </AppBar>
            <Router>                    
              <Routes>                                                                        
                <Route path="/" element={<Home/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
              </Routes> 
            </Router>                   
        </main>
    
      </div>
    </AuthUserProvider>
  );
}

export default App;
